import React from 'react';
import './scrollTo.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

const ScrollTo = () => {

    const scrollHandler = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
    }

    return (
        <div className="scrollTo" onClick={scrollHandler}>
            <FontAwesomeIcon icon={faArrowUp} />
        </div>
    )
}

export default ScrollTo;