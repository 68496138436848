import React from 'react';
import './bigImage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const BigImage = (props) => {
    return (
        props.show ? <div className="bigImage">
            <img src={props.img.url} alt={props.img.title} />
            <span className="closeIcon" onClick={props.close}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
            <div className="title">{props.img.title}</div>
        </div> : null
    );
}

export default BigImage;