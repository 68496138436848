import React, { useState, useEffect } from 'react';
import './photoGallery.css';
import Images from './images/images';
import axios from 'axios';
import Loading from '../../loading/loading';
import Message from '../../ui/message/message';
import BackDrop from '../../ui/backDrop/backDrop';
import BigImage from './bigImage/bigImage';

const Home = () => {

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedImg, setSelectedImg] = useState();


    useEffect(() => {
        setLoading(true)
        axios.get('http://oterma.ir/server/showImages.php')
            .then((response) => {
                setImages(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setError(true);
            })
    }, []);

    const showImageHandler = (id) => {
        const imagess = [...images]
        const findIndex = imagess.findIndex((img) => {
            return img.id === id;
        })
        const selectImg = images[findIndex]
        setSelectedImg(selectImg)
        setShow(true);
    }

    const closeHandler = () => {
        setShow(false);
        setSelectedImg(null);
    }

    return (
        <div className="row m-0 py-5 gallery">
            <div className="col-12">
                <BackDrop show={show} />
                <BigImage
                    show={show}
                    img={selectedImg}
                    close={closeHandler}
                />
                {loading ? <Loading /> : <Images images={images} showImageBig={showImageHandler} />}
                {error ? <Message msgType="danger">خطا در برقراری ارتباط با سرور! لطفا مجددا امتحان کنید.</Message> : null}
            </div>
        </div>
    );
}

export default Home;