import React, { useState } from 'react';

export const ThemeContext = React.createContext()

const ThemeContextProvider = (props) => {

    const [Theme, setLightTheme] = useState(true);

    const [light] = useState({
        bg: '#eff1f7',
        colorText: '#18143d'
    });

    const [dark] = useState({
        bg: '#18143d',
        colorText: '#eff1f7'
    });

    const changeTheme = () => {
        setLightTheme(!Theme)
    }

    return (
        <ThemeContext.Provider value={{ changeTheme, Theme, light, dark }}>
            {props.children}
        </ThemeContext.Provider>
    );
}

export default ThemeContextProvider;