import React from 'react';
import './addWorkSample.css';
import Button from '../../../ui/button/button';

const AddWorkSample = (props) => {
    return (
        <div className="row m-0 justify-content-center text-center addWorkSample">
            <div className="col-12 col-md-10">
                <h4 className="mb-4">اضافه کردن نمونه کار جدید</h4>
                <label>عنوان نمونه کار</label>
                <input type="text" onChange={props.titleHandler} />
                <label>آدرس تصویر نمونه کار</label>
                <input type="text" onChange={props.urlImgWorkHandler} />
                <label>نوع نمونه کار</label>
                <input type="text" onChange={props.typeWorkHandler} />
                <label>لینک نمونه کار</label>
                <input type="text" onChange={props.linkWorkHandler} />
                <label>
                    <Button btnType="primary" clicked={props.AddWorkSampleHandler}>اضافه کردن</Button>
                </label>
            </div>
        </div>
    );
}

export default AddWorkSample;