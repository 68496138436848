import React, { useContext } from 'react';
import './dashboardItems.css';
import DashboardItem from './dashboardItem/dashboardItem';
import { faHome, faVideo, faFileCode } from '@fortawesome/free-solid-svg-icons'
import { faImages } from '@fortawesome/free-regular-svg-icons';
import { AuthContext } from '../../../context/auth-context';
import { withRouter } from 'react-router-dom'

const DashboardItems = (props) => {

    const authContext = useContext(AuthContext);

    const logOut = () => {
        authContext.dispatch({ type: 'logout' })
        props.exitDashboard();
    }

    return (
        <ul className="m-0 p-0 list-unstyled dashboardItems" >
            <DashboardItem link="/admin/dashboard/" icon={faHome}>داشبورد</DashboardItem>
            <DashboardItem link="/admin/dashboard/dashboardImages" icon={faImages}>تصاویر</DashboardItem>
            <DashboardItem link="/admin/dashboard/dashboardWorksSpace" icon={faFileCode}>نمونه کار ها</DashboardItem>
            <DashboardItem link="/admin/dashboard/dashboardVideos" icon={faVideo}>ویدیو ها</DashboardItem>
            <div className="row m-0 pt-5 justify-content-center btn-exit">
                <button onClick={logOut} >خروج از حساب</button>
            </div>
        </ul>
    );
}

export default withRouter(DashboardItems);