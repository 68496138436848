import React from 'react';
import './banner.css';
import Particle from '../particle/particle';
import TypedJs from '../typed/typed';
import ClipPath from './clip-path/clip-path';
import SectionAbout from '../section-about/section-about';

const Banner = (props) => {
    return (
        <div className="row m-0 banner">
            <div className="cover"></div>
            <div className="particle">
                <Particle />
            </div>
            <div className="typed">
                <TypedJs />
            </div>
            <ClipPath />
            <SectionAbout />
        </div>
    );
}

export default Banner;