import React, { useState } from 'react';
import './dashboardWorksSpace.css';
import Button from '../../ui/button/button';
import BackDrop from '../../ui/backDrop/backDrop';
import Modal from '../../ui/modal/modal';
import Loading from '../../loading/loading';
import WorkSampleDashboard from './workSampleDashboard/workSampleDashboard';
import AddWorkSample from './addWorkSample/addWorkSample';
import EditWorkSample from './editWorkSample/editWorkSample';
import { ToastContainer, toast } from 'react-toastify';

const DashboardWorksSpace = (props) => {

    //تنظیمات مربوط به toast
    const options = {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: false,
        className: 'toastOption'
    };

    /*********state*************/
    const [show, setshow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [actionType, setActionType] = useState('');
    const [infoWorkSample, setInfoWorkSample] = useState({ titleWork: '', urlImgWork: '', typeWork: '', linkWork: '' });
    const [infoEditWork, setInfoEditWork] = useState({ idWork: null, titleWork: null, typeWork: null });
    /*********state*************/

    const addWorkSample = () => {
        setshow(true);
        setActionType('addWorkSample')
    }

    const closeModalHandler = () => {
        setshow(false);
    }

    const titleHandler = (event) => {
        setInfoWorkSample({ ...infoWorkSample, titleWork: event.target.value })
    }
    const urlImgWorkHandler = (event) => {
        setInfoWorkSample({ ...infoWorkSample, urlImgWork: event.target.value })
    }
    const typeWorkHandler = (event) => {
        setInfoWorkSample({ ...infoWorkSample, typeWork: event.target.value })
    }
    const linkWorkHandler = (event) => {
        setInfoWorkSample({ ...infoWorkSample, linkWork: event.target.value })
    }

    //تابع اضافه کردن نمونه کار به سرور
    const AddWorkSampleHandler = () => {
        setshow(false);
        setLoading(true);
        fetch('http://oterma.ir/server/insertWorkSample.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title: infoWorkSample.titleWork,
                url: infoWorkSample.urlImgWork,
                typeWork: infoWorkSample.typeWork,
                link: infoWorkSample.linkWork,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === 'success') {
                    setLoading(false);
                    props.requestWorkSample();
                    toast.success("نمونه کار جدید اضافه شد", options);
                } else {
                    setLoading(false);
                    toast.warn("خطا در ارسال نمونه کار", options);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("خطا در ارتباط با سرور", options);
            })
    }

    //تابع حذف نمونه کار از سرور
    const deleteWorkHandler = (id) => {
        setLoading(true);
        fetch('http://oterma.ir/server/deleteWork.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === 'delete succefull') {
                    setLoading(false);
                    props.requestWorkSample()
                    toast.success("نمونه کار با موفقیت حذف شد", options);
                }
                else if (responseJson === 'delete fail') {
                    setLoading(false);
                    toast.warn("خطا در ویرایش نمونه کار", options);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("نمونه کار حذف نشد - خطا در ارتباط با سرور", options);
            })
    }

    //تابع اولیه برای ویرایش نمونه کار
    const editHandler = (title, typeWork, idWork) => {
        setshow(true);
        setActionType('editWorkSample')
        setInfoEditWork({ idWork: idWork, titleWork: title, typeWork: typeWork })
    }

    const changeTitleHandler = (event) => {
        setInfoEditWork({ ...infoEditWork, titleWork: event.target.value })
    }

    const changeTypeHandler = (event) => {
        setInfoEditWork({ ...infoEditWork, typeWork: event.target.value })
    }

    //تابع اصلی برای ویرایش نمونه کار
    const editWorkSampleHandler = () => {
        setshow(false)
        setLoading(true)
        fetch('http://oterma.ir/server/updateWorkSample.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: infoEditWork.idWork,
                titleWork: infoEditWork.titleWork,
                typeWork: infoEditWork.typeWork,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === 'succefull') {
                    setLoading(false);
                    props.requestWorkSample();
                    toast.success("نمونه کار با موفقیت ویرایش شد", options);
                }
                else if (responseJson === 'faild') {
                    setLoading(false);
                    toast.warn("خطا در ویرایش نمونه کار", options);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("خطا در ارتباط با سرور - نمونه کار ویرایش نشد", options);
            })
    }

    //شرطی برای نشان دادن کامپوننت بر اساس state
    let action = null
    if (actionType === 'addWorkSample') {
        action = <AddWorkSample
            titleHandler={titleHandler}
            urlImgWorkHandler={urlImgWorkHandler}
            typeWorkHandler={typeWorkHandler}
            linkWorkHandler={linkWorkHandler}
            AddWorkSampleHandler={AddWorkSampleHandler}
        />
    }
    if (actionType === 'editWorkSample') {
        action = <EditWorkSample
            titleWork={infoEditWork.titleWork}
            typeWork={infoEditWork.typeWork}
            titleHandler={changeTitleHandler}
            typeHandler={changeTypeHandler}
            editWorkSampleHandler={editWorkSampleHandler}
        />
    }

    return (
        <div className="row m-0 dashboardWorkSpace">
            <div className="col-12 p-0">
                <div className="div-dashboard-title">
                    <p className="m-0">نمونه کارها</p>
                </div>
                <div className="row justify-content-center pt-4">
                    <Button btnType="primary" clicked={addWorkSample}>اضافه کردن نمونه کار</Button>
                </div>
                <div className="row m-0 py-4 justify-content-between ">
                    {loading ? <Loading /> : props.workSamples.map((work, index) => {
                        return <WorkSampleDashboard
                            key={index}
                            id={work.id}
                            number={index + 1}
                            title={work.title}
                            typeWork={work.typeWork}
                            deleted={deleteWorkHandler}
                            edited={editHandler}
                        />
                    })}
                </div>
            </div>
            <BackDrop show={show} />
            <Modal show={show} closeModal={closeModalHandler}>
                {action}
            </Modal>
            <ToastContainer />
        </div>
    );
}

export default DashboardWorksSpace;