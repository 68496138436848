import React from 'react';
import './addImage.css';
import Button from '../../../ui/button/button';

const AddImage = (props) => {
    return (
        <div className="row m-0 justify-content-center text-center addImage">
            <div className="col-12 col-md-10">
                <h4 className="mb-4">اضافه کردن تصویر جدید</h4>
                <label>نام تصویر</label>
                <input type="text" value={props.titleImg} onChange={props.titleImgHandler} />
                <label>آدرس تصویر</label>
                <input type="text" value={props.urlImg} onChange={props.urlImgHandler} />
                <label>
                    <Button btnType="primary" clicked={props.addImageHandler}>اضافه کردن</Button>
                </label>
            </div>
        </div>
    );
}

export default AddImage;