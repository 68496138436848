import React from 'react';
import './imgDashboard.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import ReactTooltip from 'react-tooltip';

const ImgDashboard = (props) => {
    return (
        <div className="col-12 col-md-11 col-lg-10 col-xl-8 p-0">
            <div className="row justify-content-between align-items-center w-100 img-info ">
                <label className="number">{props.number}</label>
                <div className="smallImg">
                    <img src={props.url} />
                </div>
                <div className="col-8 col-md-5 d-flex justify-content-start align-items-center titleImg"><label> نام تصویر:</label> {props.title}</div>
                <div className="ManagementInfo">
                    <FontAwesomeIcon
                        icon={faEdit}
                        className="mx-1"
                        fixedWidth size="lg"
                        color="#1aafe8"
                        data-tip="ویرایش"
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.edited(props.id, props.title, props.url)}
                    />
                    <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="mx-1"
                        fixedWidth size="lg"
                        color="#fb4444"
                        data-tip="حذف"
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.deleted(props.id)}
                    />
                    <ReactTooltip effect="solid" />
                </div>
            </div>
        </div >
    );
}

export default ImgDashboard;