import React from 'react';
import './socialMediaBox.css';

import SocialMedia from './socialMedia/socialMedia';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const SocialMediaBox = () => {
    return (
        <div className="row m-0 justify-content-center">
            <SocialMedia icon={faInstagram} name="Instagram" link="https://www.instagram.com/sajjadramezani/" />
            <SocialMedia icon={faTelegram} name="Telegram" link="https://t.me/sajjadr73" />
            <SocialMedia icon={faWhatsapp} name="Whatsapp" link="https://api.whatsapp.com/send?phone=989371680704" />
        </div>
    );
}

export default SocialMediaBox;
