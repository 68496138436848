import React from 'react';
import './dashboardMain.css';
import CardInfo from './card-info/card-info';
import { faVideo, faFileCode } from '@fortawesome/free-solid-svg-icons'
import { faImages } from '@fortawesome/free-regular-svg-icons'

const DashboardMain = (props) => {

    return (
        <div className="row m-0 dashboardMain">
            <div className="col-12 p-0">
                <div className="div-dashboard-title">
                    <p className="m-0">داشبورد</p>
                </div>
                <div className="row m-0 pt-5 flex-column align-items-center flex-md-row ">
                    <CardInfo
                        icon={faImages}
                        colorIcon="cardRed"
                        titleCard="تصاویر"
                        length={props.lengthImg}
                    />
                    <CardInfo
                        icon={faVideo}
                        colorIcon="cardOrange"
                        titleCard="ویدیوها"
                        length={props.lengthVideo}
                    />
                    <CardInfo
                        icon={faFileCode}
                        colorIcon="cardBlue"
                        titleCard="نمونه کارها"
                        length={props.lengthWorkSample}
                    />
                </div>
            </div>
        </div>
    );
}

export default DashboardMain;