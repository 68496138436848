import React from 'react';
import './resumeInfo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const resumeInfo = (props) => {
    return (
        <div className="row resumeInfo">
            <div className="row m-0 w-100 title">
                <FontAwesomeIcon icon={props.icon} size="lg" fixedWidth />
                <p className="m-0 ml-2 fs-1_1 fw-500">{props.title}</p>
            </div>
            <hr />
            <div className="row m-0 w-100">{props.children}</div>
        </div>
    );
}

export default resumeInfo;