import React from 'react';
import './workSample.css';
import Button from '../../../../ui/button/button';

const WorkSample = (props) => {
    return (
        <div className="col-12 col-sm-8 col-md-6 col-lg-4 workSample">
            <div className="card">
                <div className="pic-work">
                    <img src={props.src} alt={props.title} />
                </div>
                <div className="py-3 title-work">
                    <p className="m-0 text-secondary fw-500 fs-1_1">{props.title}</p>
                </div>
                <div className="row m-0 link-work">
                    <div className="col-6">
                        <Button btnType="primary">
                            <a href={props.link} target="_blank" rel="noopener noreferrer">مشاهده</a>
                        </Button>
                    </div>
                    <div className="col-6 d-flex justify-content-end align-items-center type">
                        <span className="type">{props.typeWork}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkSample;