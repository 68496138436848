import React from 'react';
import './resume.css';
import { faUser, faUserGraduate, faToolbox } from '@fortawesome/free-solid-svg-icons'
import ResumeInfo from './resumeInfo/resumeInfo';

const Resume = () => {
    return (
        <div className="row m-0 py-5 justify-content-center resume">
            <div className="col-12">
                <ResumeInfo icon={faUser} title="درباره من">
                    <div className="col-12 col-md-6 col-lg-4 my-2 d-flex justify-content-between justify-content-md-start">
                        <p className="m-0 text-dark title-about">نام:</p>
                        <p className="m-0 text-secondary fs-0_9 fw-400 ml-2">سجاد</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 my-2 d-flex justify-content-between justify-content-md-start">
                        <p className="m-0 text-dark title-about">نام خانوادگی:</p>
                        <p className="m-0 text-secondary fs-0_9 fw-400 ml-2">رمضانی</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 my-2 d-flex justify-content-between justify-content-md-start">
                        <p className="m-0 text-dark title-about">تاریخ تولد:</p>
                        <p className="m-0 text-secondary fs-0_9 fw-400 ml-2">۲۵ - ۲۱ - ۱۳۷۳</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 my-2 d-flex justify-content-between justify-content-md-start">
                        <p className="m-0 text-dark title-about">خدمت سربازی:</p>
                        <p className="m-0 text-secondary fs-0_9 fw-400 ml-2">پایان خدمت</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 my-2 d-flex justify-content-between justify-content-md-start">
                        <p className="m-0 text-dark title-about">وضعیت تاهل:</p>
                        <p className="m-0 text-secondary fs-0_9 fw-400 ml-2">مجرد</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 my-2 d-flex justify-content-between justify-content-md-start">
                        <p className="m-0 text-dark title-about">ایمیل:</p>
                        <p className="m-0 text-secondary fs-0_9 fw-400 ml-2">sajjad_rmz73@yahoo.com</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 my-2 d-flex justify-content-between justify-content-md-start">
                        <p className="m-0 text-dark title-about">شماره تماس:</p>
                        <p className="m-0 text-secondary fs-0_9 fw-400 ml-2">۰۹۳۷۱۶۸۰۷۰۴</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 my-2 d-flex justify-content-between justify-content-md-start">
                        <p className="m-0 text-dark title-about">آدرس:</p>
                        <p className="m-0 text-secondary fs-0_9 fw-400 ml-2">خراسان رضوی - مشهد </p>
                    </div>
                </ResumeInfo>
                <ResumeInfo icon={faUserGraduate} title="سوابق تحصیلی">
                    <div className="col-auto mx-2">
                        <p className="m-0 text-dark fs-1 fw-500">۱ -</p>
                    </div>
                    <div className="col-auto mx-2">
                        <p className="m-0 text-dark fs-1 fw-500">کارشناسی پیوست</p>
                    </div>
                    <div className="col-auto mx-2">
                        <p className="m-0 text-danger fs-1 fw-500">۹۶ - ۹۲</p>
                    </div>
                    <div className="col-auto mx-2">
                        <p className="m-0 text-dark fs-1 fw-400">مهندسی برق - الکترونیک</p>
                    </div>
                    <div className="col-auto mx-2">
                        <p className="m-0 text-dark fs-1 fw-400">موسسه آموزش عالی اقبال لاهوری</p>
                    </div>
                </ResumeInfo>
                <ResumeInfo icon={faToolbox} title="سوابق کاری">
                    <div className="col-auto mx-2">
                        <p className="m-0 text-dark fs-1 fw-500">۱ -</p>
                    </div>
                    <div className="col-auto mx-2">
                        <p className="m-0 text-dark fs-1 fw-500">کاج سبز سپهر آذین</p>
                    </div>
                    <div className="col-auto mx-2">
                        <p className="m-0 text-danger fs-1 fw-500">پروژه ای</p>
                    </div>
                    <div className="col-auto mx-2">
                        <p className="m-0 text-dark fs-1 fw-400">طراح و برنامه نویس فرانت اند</p>
                    </div>
                    <div className="col-auto mx-2">
                        <p className="m-0 text-dark fs-1 fw-400">تبدیل طرح های ui شرکت به طرح های Html و Css</p>
                    </div>
                </ResumeInfo>
            </div>
        </div>
    );
}

export default Resume;