import React, { useState } from 'react';
import './navbar.css';
import NavItems from '../navItems/navItems';
import MenuResponsive from '../../menu-responsive/menu-responsive';

const Navbar = () => {

    const [openMenu, setOpenMenu] = useState(false);

    let classes = ['showMenuIcon']
    switch (openMenu) {
        case true:
            classes.push('active')
    }

    const menuHandler = () => {
        setOpenMenu(!openMenu)
    }

    return (
        <React.Fragment>
            <div className="navbar-items mt-4 mt-lg-0">
                <div className="showNavItems">
                    <NavItems />
                </div>
                <div className={classes.join(' ')}>
                    <span className="m-0" onClick={menuHandler}>
                        <div className="bar"></div>
                    </span>
                </div>
            </div>
            <div className="menu-responsive">
                <MenuResponsive openMenu={openMenu} />
            </div>
        </React.Fragment>
    );
}

export default Navbar;