import React from 'react';
import './menu-responsive.css';
import NavItem from '../navigation/navItems/navItem/navItem';

const MenuResponsive = (props) => {

    let classes = ['menu']

    switch (props.openMenu) {
        case true:
            classes.push('openMenu')
    }

    return (
        <div className={classes.join(' ')}>
            <NavItem link="/">گالری تصاویر</NavItem>
            <NavItem link="/Work-samples">نمونه کار ها</NavItem>
            <NavItem link="/Resume">رزومه</NavItem>
            <NavItem link="/contact">مهارت ها</NavItem>
        </div>
    );
}

export default MenuResponsive;