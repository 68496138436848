import React from 'react';
import NavItem from './navItem/navItem';
import './navItems.css';

const NavItems = () => {
    return (
        <ul className="m-0 navItems">
            <NavItem link="/">گالری تصاویر</NavItem>
            <NavItem link="/Work-samples">نمونه کار ها</NavItem>
            <NavItem link="/Resume">رزومه</NavItem>
            <NavItem link="/contact">مهارت ها</NavItem>
        </ul>
    );
}

export default NavItems;