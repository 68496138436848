import React from 'react';
import Wrapper from '../../hoc/wrapper';
import HomePage from '../../containers/homePage/homePage';
import Admin from '../pages/admin/admin';
import Dashboard from '../pages/dashpoard/dashboard';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AuthContextProvider from '../../context/auth-context';
import ThemeContextProvider from '../../context/theme-context';

const Layout = () => {

    return (
        <BrowserRouter>
            <AuthContextProvider>
                <ThemeContextProvider>
                    <Wrapper>
                        <div className="container-fluid p-0">
                            <Switch>
                                <Route path="/" exact component={HomePage} />
                                <Route path="/admin" exact component={Admin} />
                                <Route path="/admin/dashboard" exact component={Dashboard} />
                            </Switch>
                        </div>
                    </Wrapper>
                </ThemeContextProvider>
            </AuthContextProvider>
        </BrowserRouter>
    );
}

export default Layout;