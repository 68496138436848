import React from 'react';
import './images.css';
import Image from './image/image';

const Images = (props) => {
    return (
        <div className="images">
            {props.images.map((image) => {
                return <Image
                    key={image.id}
                    id={image.id}
                    title={image.title}
                    src={image.url}
                    clicked={() => props.showImageBig(image.id)}
                />
            })}
        </div>
    );
}

export default Images;