import React from 'react';
import './card-info.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CardInfo = (props) => {
    return (
        <div className={`div-card ${props.colorIcon}`}>
            <div className="card-icon">
                <FontAwesomeIcon icon={props.icon} />
            </div>
            <div className="card-info">
                <p className="m-0 text-secondary ">تعداد {props.titleCard}</p>
                <hr className="m-0" />
                <p className="m-0">{props.length} عدد</p>
            </div>
        </div>
    );
}

export default CardInfo;