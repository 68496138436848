import React from 'react';
import './dashboardVideos.css';
import Button from '../../ui/button/button';

const DashboardVideos = () => {
    return (
        <div className="row m-0 dashboardVideo">
            <div className="col-12 p-0">
                <div className="div-dashboard-title">
                    <p className="m-0">ویدیو ها</p>
                </div>
                <div className="row justify-content-center pt-4">
                    <Button btnType="primary">اضافه کردن ویدیو</Button>
                </div>
                <div className="row m-0 py-4 justify-content-center">

                </div>
            </div>
        </div>
    );
}

export default DashboardVideos;