import React from 'react';
import './modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Modal = (props) => {
    return (
        props.show ?
            <div className="modal">
                <span className="iconClose" onClick={props.closeModal}><FontAwesomeIcon icon={faTimes} /></span>
                {props.children}
            </div> : null
    );
}

export default Modal;