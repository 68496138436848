import React from 'react';
import './listWorkSamples.css';
import WorkSample from './workSample/workSample';

const ListWorkSamples = (props) => {
    return (
        <div className="row m-0 justify-content-center justify-content-md-start listWorkSamples">
            {props.workSapmles.map((item) => {
                return <WorkSample
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    src={item.url}
                    link={item.link}
                    typeWork={item.typeWork}
                />
            })}
        </div>
    );
}

export default ListWorkSamples;