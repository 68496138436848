import React from 'react';
import './clip-path.css';
import clipPath from './clip-path.svg'

const ClipPath = () => {
    return (
        <div className="clip-path">
            <img src={clipPath} alt="clip-path" />
        </div >
    );
}

export default ClipPath;