import React from 'react';
import './message.css';

const Message = (props) => {
    return (
        <div className={`message ${props.msgType}`} >
            <span>{props.children}</span>
        </div >
    );
}

export default Message;