import React, { useState, useContext } from 'react';
import Button from '../../ui/button/button';
import './admin.css';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../context/auth-context';
import { ToastContainer, toast } from 'react-toastify';

const Admin = (props) => {

    /********state***********/
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const authContext = useContext(AuthContext)

    //تنظیمات مربوط به toast
    const options = {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: false,
        className: 'toastOption'
    };
    /********state***********/

    const usernameChangeHandler = (event) => {
        setUsername(event.target.value)
    }
    const passwordChangeHandler = (event) => {
        setPassword(event.target.value)
    }

    const validate = () => {
        if (username === '') {
            toast.error("نام کاربری را وارد کنید", options);
            return false;
        }
        else if (password === '') {
            toast.error("رمز عبور را وارد کنید", options);
            return false;
        }
        return true;
    }

    const loginHandler = () => {
        const validateResult = validate();
        if (validateResult) {
            console.log('http request send');
            fetch('http://oterma.ir/server/user_login.php', {
                method: 'post',
                headers: {
                    "accept": 'application/json',
                    "content-type": 'application/json',
                },
                body: JSON.stringify({
                    userName: username,
                    password: password,
                })
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson === 'Data Matched') {
                        authContext.dispatch({ type: 'login', peyload: username })
                        toast.success("اکنون وارد شدید", options);
                    }
                    else {
                        toast.error("نام کاربری یا رمز عبور صحیح نیست", options);
                    }
                })
                .catch((error) => {
                    toast.error("خطا در ارتباط با سرور", options);
                })
        }

    }

    let redirect = null;
    if (authContext.authenticated) {
        redirect = <Redirect to="/admin/dashboard" />
    }

    return (
        <React.Fragment>
            {redirect}
            <div className="row m-0 justify-content-center align-items-center admin">
                <div className="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-4 px-2">
                    <div className="div-login">
                        <h4 className="mb-4">ورود به حساب کاربری</h4>
                        <label>نام کاربری:</label>
                        <input type="text" placeholder="نام کاربری" value={username} onChange={usernameChangeHandler} />
                        <label>رمز عبور:</label>
                        <input type="password" placeholder="رمز عبور" value={password} onChange={passwordChangeHandler} />
                        <Button btnType="primary" clicked={loginHandler}>ورود</Button>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </React.Fragment>
    );
}

export default Admin;