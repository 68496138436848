import React from 'react';
import Typed from 'react-typed';

const TypedJs = () => {
    return (
        <React.Fragment>
            <Typed
                strings={[
                    'I Am Web Programmer',
                    'I Am Web Designer',
                    'I Am Freelancer Designer']}
                typeSpeed={60}
                backSpeed={50}
                loop
                cursorChar="|" />
        </React.Fragment>
    );
}

export default TypedJs;
