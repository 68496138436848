import React, { useState } from 'react';
import './skills.css';

import SkillBar from 'react-skillbars';

const Skills = () => {

    const [skills] = useState([
        { id: "1", type: 'Html', level: 95 },
        { id: "2", type: 'Css', level: 90 },
        { id: "3", type: 'Bootstrap', level: 90 },
        { id: "4", type: 'Javascript', level: 60 },
        { id: "5", type: 'React', level: 50 },
        { id: "6", type: 'Git - Github', level: 60 },
        { id: "7", type: 'Sass', level: 60 },
    ])
    const [colors] = useState({
        bar: 'rgb(2, 168, 2)',
        title: {
            text: 'rgb(44, 44, 44)',
            background: 'transparent',
        }
    })



    return (
        <div className="row m-0 py-5 justify-content-center">
            <div className="col-12 col-lg-10 div-skills">
                <SkillBar skills={skills} colors={colors} height={13} />
            </div>
        </div>
    );
}

export default Skills;