import React from 'react';
import './main.css'
import Navbar from '../../components/navigation/navbar/navbar';
import PhotoGallery from '../../components/pages/photoGallery/photoGallery';
import WorkSamples from '../../components/pages/WorkSamples/WorkSamples';
import Page404 from '../../components/pages/page404/page404';
import Resume from '../../components/pages/resume/resume';
import Contact from '../../components/pages/skills/skills';
import { BrowserRouter, Switch, Route } from "react-router-dom";

const Main = () => {
    return (
        <BrowserRouter>
            <div className="main rtl">
                <Navbar />
                <div className="container div-pages">
                    <Switch>
                        <Route path="/" exact component={PhotoGallery} />
                        <Route path="/Work-samples" exact component={WorkSamples} />
                        <Route path="/Resume" exact component={Resume} />
                        <Route path="/Contact" exact component={Contact} />
                        <Route component={Page404} />
                    </Switch>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default Main;