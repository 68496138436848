import React, { useState } from 'react';
import './editImage.css';
import Button from '../../../ui/button/button';

const EditImage = (props) => {
    const [id, setId] = useState(props.idImg);
    const [title, settitle] = useState(props.titleImg);
    const [url, seturl] = useState(props.urlImg);

    const editImageHandler = () => {
        props.setshow(false)
        props.setLoading(true)
        fetch('http://oterma.ir/server/updateImage.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
                titleImg: title,
                urlImg: url,
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === 'succefull') {
                    console.log(responseJson);
                    props.requestImages()
                    props.setLoading(false)
                }
                else if (responseJson === 'faild') {
                    console.log(responseJson);
                    props.setLoading(false)
                }
            })
            .catch((error) => {
                console.log(error);
                props.setLoading(false)
            })
    }

    return (
        <div className="row m-0 justify-content-center text-center editImage">
            <div className="col-12 col-md-10">
                <h4 className="mb-4">ویرایش تصویر</h4>
                <label>نام تصویر</label>
                <input type="text" value={title} onChange={(event) => settitle(event.target.value)} />
                <label>آدرس تصویر</label>
                <input type="text" value={url} onChange={(event) => seturl(event.target.value)} />
                <label>
                    <Button btnType="primary" clicked={editImageHandler}>ویرایش</Button>
                </label>
            </div>
        </div>
    );
}

export default EditImage;