import React, { useEffect, useState } from 'react';
import './homePage.css';
import Banner from '../../components/banner/banner';
import Main from '../main/main';
import Footer from '../footer/footer';
import ScrollTo from '../../components/scrollTo/scrollTo';

const HomePage = () => {

    const [scrollValue, setScrollValue] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > window.innerHeight / 2) {
                setScrollValue(true)
            }
            else {
                setScrollValue(false)
            }
        });
    })

    return (
        <div className="row m-0 homePage">
            <div className="container-fluid p-0">
                <Banner />
                <Main />
                <Footer />
                {scrollValue ? <ScrollTo /> : null}
            </div>
        </div>
    );
}

export default HomePage;