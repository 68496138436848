import React from 'react';
import './section-about.css';
import picFace from '../../assets/images/person.jpg';

const SectionAbout = () => {
    return (
        <div className="row m-0 sectionAbout rtl">
            <div className="col-auto p-2 p-lg-3">
                <p>سجاد رمضانی</p>
                <p>برنامه نویس و طراحی سایت سمت کاربر</p>
                <p>(Front-End Developer)</p>
            </div>
            <div className="col-auto p-2 p-lg-3 picFace">
                <img src={picFace} alt="sajjad" />
            </div>
        </div>
    );
}

export default SectionAbout;