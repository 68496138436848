import React from 'react';
import './image.css';

const Image = (props) => {
    return (
        <div className="image">
            <img src={props.src} onClick={props.clicked} alt={props.title} />
            <div className="number">{props.id}</div>
            <div className="title">image {props.id}</div>
        </div>
    );
}

export default Image;