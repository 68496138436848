import React, { useState } from 'react';
import './dashboardImages.css';
import ImgDashboard from './imgDashboard/imgDashboard';
import Button from '../../ui/button/button';
import BackDrop from '../../ui/backDrop/backDrop';
import Modal from '../../ui/modal/modal';
import AddImage from './addImage/addImage';
import EditImage from './editImage/editImage';
import Loading from '../../loading/loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const DashboardImages = (props) => {
    //تنظیمات مربوط به toast
    const options = {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: false,
        className: 'toastOption'
    };

    /*********state*************/
    const [actionType, setActionType] = useState('');
    const [show, setshow] = useState(false);
    const [titleImg, setTitleImg] = useState('');
    const [urlImg, setUrlImg] = useState('');
    const [idImg, setIdImg] = useState();
    const [loading, setLoading] = useState(false);
    /*********state*************/

    const addImage = () => {
        setshow(true);
        setActionType('addImage')
        setTitleImg()
        setUrlImg()
    }
    const closeModalHandler = () => {
        setshow(false);
    }

    const titleImgHandler = (event) => {
        setTitleImg(event.target.value)
    }
    const urlImgHandler = (event) => {
        setUrlImg(event.target.value)
    }

    //تابع اضافه کردن تصویر به سرور
    const addImageHandler = () => {
        setshow(false);
        setLoading(true);
        fetch('http://oterma.ir/server/insertImages.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title: titleImg,
                url: urlImg,
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === 'success') {
                    props.requestImages();
                    setLoading(false);
                    toast.success("تصویر اضافه شد", options);
                } else {
                    toast.warn("خطا در ارسال تصویر", options);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("خطا در ارتباط با سرور", options);
            });
        setTitleImg()
        setUrlImg()
    }

    //تابع حذف تصویر از سرور
    const deleteImgHandler = (id) => {
        setLoading(true);
        fetch('http://oterma.ir/server/deleteimage.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                props.requestImages();
                setLoading(false);
                toast.success("تصویر با موفقیت حذف شد", options);
            })
            .catch((error) => {
                setLoading(false);
                toast.error("خطا: تصویر حذف نشد", options);
            })
    }

    //تابع اولیه برای ویرایش تصاویر
    const editHandler = (id, title, url) => {
        setshow(true);
        setActionType('editImage')
        setTitleImg(title)
        setUrlImg(url)
        setIdImg(id)
    }

    //شرطی برای نشان دادن کامپوننت بر اساس state
    let action = null;
    if (actionType === 'addImage') {
        action = <AddImage
            addImageHandler={addImageHandler}
            titleImgHandler={titleImgHandler}
            urlImgHandler={urlImgHandler}
            titleImg={titleImg}
            urlImg={urlImg}
        />
    }
    else if (actionType === 'editImage') {
        action = <EditImage
            titleImg={titleImg}
            urlImg={urlImg}
            idImg={idImg}
            requestImages={props.requestImages}
            setLoading={setLoading}
            setshow={setshow}
        />
    }

    return (

        <div className="row m-0 dashboardImage">
            <div className="col-12 p-0">
                <div className="div-dashboard-title">
                    <p className="m-0">تصاویر</p>
                </div>
                <div className="row justify-content-center pt-4">
                    <Button btnType="primary" clicked={addImage}>اضافه کردن تصویر</Button>
                </div>
                <div className="row m-0 py-4 justify-content-center">
                    {loading ? <Loading /> : props.images.map((img, index) => {
                        return <ImgDashboard
                            key={index}
                            number={index + 1}
                            title={img.title}
                            url={img.url}
                            id={img.id}
                            deleted={deleteImgHandler}
                            edited={editHandler}
                        />
                    })}
                </div>
            </div>
            <BackDrop show={show} />
            <Modal show={show} closeModal={closeModalHandler}>
                {action}
            </Modal>
            <ToastContainer />
        </div>
    );
}

export default React.memo(DashboardImages);