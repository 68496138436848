import React from 'react';
import './navItem.css';
import { NavLink } from 'react-router-dom';

const NavItem = (props) => {
    return (
        <li className="navItem">
            <NavLink to={props.link} exact >{props.children}</NavLink>
        </li>
    );
}

export default NavItem;