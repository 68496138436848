import React from 'react';
import Button from '../../../ui/button/button';
import './editWorkSample.css';

const EditWorkSample = (props) => {
    return (
        <div className="row m-0 justify-content-center text-center editWorkSample">
            <div className="col-12 col-md-10">
                <h4 className="mb-4">ویرایش نمونه کار</h4>
                <label>عنوان نمونه کار</label>
                <input type="text" value={props.titleWork} onChange={props.titleHandler} />
                {/* <label>آدرس تصویر نمونه کار</label>
                <input type="text" /> */}
                <label>نوع نمونه کار</label>
                <input type="text" value={props.typeWork} onChange={props.typeHandler} />
                {/* <label>لینک نمونه کار</label>
                <input type="text" /> */}
                <label>
                    <Button btnType="primary" clicked={props.editWorkSampleHandler}>ویرایش</Button>
                </label>
            </div>
        </div>
    );
}

export default EditWorkSample;