import React from 'react';
import './dashboardItem.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashboardItem = (props) => {
    return (
        <li className="dashboardItem">
            <NavLink to={props.link} exact><span><FontAwesomeIcon icon={props.icon} /></span>{props.children}</NavLink>
        </li>
    );
}

export default DashboardItem;