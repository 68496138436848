import React from 'react';
import './footer.css';
import SocialMediaBox from '../../components/socilaMediaBox/socialMediaBox';
import video from '../../assets/video/video.mp4';

const Footer = () => {
    return (
        <div className="row m-0 footer">
            <div className="video">
                <video src={video} loop muted autoPlay />
            </div>
            <div className="col-12 d-flex flex-column justify-content-center">
                <div className="row m-0 justify-content-center align-items-center mb-3 ways-contact">
                    <span className="right"></span>
                    <p>راه های ارتباطی</p>
                    <span className="left"></span>
                </div>
                <SocialMediaBox />
                <div className="copyRight">
                    <p>sajjad ramezani 2020 | ALL RIGHTS RESERVED</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;