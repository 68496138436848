import React from 'react';
import './workSampleDashboard.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const WorkSampleDashboard = (props) => {
    return (
        <div className="col-12 col-xl-6">
            <div className="row justify-content-between align-items-center workSample-info">
                <span className="number">{props.number}</span>
                <span className="col-7 col-xl-5 titleWork">{props.title}</span>
                <span className="col-auto typeWork">{props.typeWork}</span>
                <div className="ManagementInfo">
                    <FontAwesomeIcon
                        icon={faEdit}
                        className="mx-1"
                        fixedWidth size="lg"
                        color="#1aafe8"
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.edited(props.title, props.typeWork, props.id)}
                    />
                    <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="mx-1"
                        fixedWidth size="lg"
                        color="#fb4444"
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.deleted(props.id)}
                    />
                </div>
            </div>
        </div>
    );
}

export default WorkSampleDashboard;