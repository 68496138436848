import React, { useEffect, useState, useRef } from 'react';
import './workSamples.css';
import axios from 'axios';
import ListWorkSamples from './listWorkSamples/listWorkSamples';
import Loading from '../../loading/loading';

const WorkSamples = () => {

    const [workSamples, setWorkSamples] = useState([]);
    const [loading, setLoading] = useState(false);
    const [arrayHolder, setArrayHolder] = useState([]);
    const [searchBarFilter, setsearchBarFilter] = useState('');
    const searchFilter = useRef();

    useEffect(() => {
        setLoading(true)
        axios.get('http://oterma.ir/server/showWorkSamples.php')
            .then((response) => {
                setWorkSamples(response.data);
                setArrayHolder(response.data)
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
        searchFilter.current.focus();
    }, [])

    const searchValueHandler = (event) => {
        setsearchBarFilter(event.target.value);
        if (event.target.value === '') {
            setWorkSamples(arrayHolder)
        }
        else {
            const itemsData = arrayHolder.filter((item) => {
                const itemType = item.typeWork.toUpperCase();
                const textType = event.target.value.toUpperCase();
                return itemType.indexOf(textType) > -1
            })
            setWorkSamples(itemsData)
        }

    }

    return (
        <div className="row m-0 py-4 justify-content-center workSamples">
            <div className="col-12 d-flex flex-column flex-sm-row justify-content-center align-items-center mb-4 searchWorkSample">
                <label htmlFor="">جستجو نمونه کار</label>
                <input
                    type="text"
                    value={searchBarFilter}
                    onChange={searchValueHandler}
                    placeholder="work sample type ..."
                    ref={searchFilter}
                />
            </div>
            <div className="col-12">
                {loading ? <Loading /> : <ListWorkSamples workSapmles={workSamples} />}
                {/* {loading ? <Loading /> : { resultFilter } */}
            </div>
        </div>
    );
}

export default WorkSamples;