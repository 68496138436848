import React from 'react';
import './socialMedia.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialMedia = (props) => {
    return (
        <div className="socialMedia">
            <a href={props.link} target="_blank" rel="noopener noreferrer" className={props.name}>
                <span><FontAwesomeIcon icon={props.icon} fixedWidth /></span>
                <p className="m-0">{props.name}</p>
            </a>
        </div>
    );
}

export default SocialMedia;