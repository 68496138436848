import React, { useEffect, useState, useContext } from 'react';
import './dashboard.css';
import DashboardItems from '../../dashboard-page/dashboardItems/dashboardItems';
import DashboardMain from '../../dashboard-page/dashboardMain/dashboardMain';
import DashboardImages from '../../dashboard-page/dashboardImages/dashboardImages';
import DashboardVideos from '../../dashboard-page/dashboardVideos/dashboardVideos';
import DashboardWorksSpace from '../../dashboard-page/dashboardWorksSpace/dashboardWorksSpace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignRight } from '@fortawesome/free-solid-svg-icons'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import axios from 'axios';
import { ThemeContext } from '../../../context/theme-context';
import { withRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';

const Dashboard = (props) => {

    const [images, setImages] = useState([]);
    const [workSamples, setWorkSamples] = useState([]);
    const [lengthVideo, setLengthVideo] = useState(0);
    const themeContext = useContext(ThemeContext);
    const options = {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: false,
        className: 'toastOption'
    };

    const theme = themeContext.Theme ? themeContext.light : themeContext.dark;

    useEffect(() => {
        requestImages()
        requestWorkSample()
        exitDashboard()
    }, [])

    //درخواست دریافت تصاویر از سرور
    const requestImages = () => {
        axios.get('http://oterma.ir/server/showImages.php')
            .then((response) => {
                setImages(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    //درخواست دریافت نمونه کارها از سرور
    const requestWorkSample = () => {
        axios.get('http://oterma.ir/server/showWorkSamples.php')
            .then((response) => {
                setWorkSamples(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const exitDashboard = () => {
        const userInfo = JSON.parse(localStorage.getItem('user'))
        if (userInfo === null) {
            props.history.replace('/admin')
        } else {
            toast.success("اکنون وارد شدید", options);
        }
    }



    return (
        <>
            < BrowserRouter >
                <div className="row m-0 rtl dashboard">
                    <div className='col-12 col-md-3 col-xl-2 p-0 sidbar'>
                        <div className="title-dashboard">
                            <FontAwesomeIcon icon={faAlignRight} />
                            <p className="m-0 ml-2">مدیریت</p>
                        </div>
                        <DashboardItems exitDashboard={exitDashboard} />
                    </div>
                    <div className="col-12 col-md-9 col-xl-10 p-0 content-dashboard">
                        <div className="account-info" style={{ background: theme.bg, color: theme.colorText }}>
                            {/* <p className="m-0">سلام سجاد</p>
                        <span>
                            <img src="" alt="" />
                        </span> */}
                            <div className="switchTheme">
                                <input type="checkbox" onChange={() => themeContext.changeTheme()} />
                            </div>
                            <div className="div-info"></div>
                        </div>
                        <div className="row m-0 div-content">
                            <div className="col-12 py-5 px-2 px-sm-3 px-md-4 px-lg-5">
                                <Switch>
                                    <Route path="/admin/dashboard" exact>
                                        <DashboardMain
                                            lengthImg={images.length}
                                            lengthWorkSample={workSamples.length}
                                            lengthVideo={lengthVideo}
                                        />
                                    </Route>
                                    <Route path="/admin/dashboard/dashboardImages" exact>
                                        <DashboardImages
                                            images={images}
                                            requestImages={requestImages}
                                        />
                                    </Route>
                                    <Route path="/admin/dashboard/dashboardWorksSpace" exact>
                                        <DashboardWorksSpace
                                            workSamples={workSamples}
                                            requestWorkSample={requestWorkSample}
                                        />
                                    </Route>
                                    <Route path="/admin/dashboard/dashboardVideos" exact component={DashboardVideos} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserRouter >
            <ToastContainer />
        </>
    );
}

export default withRouter(Dashboard);